// extracted by mini-css-extract-plugin
export var back = "styles-module--back--PNcel";
export var buttons = "styles-module--buttons--ojADZ";
export var container = "styles-module--container--OS-my";
export var dashedFon = "styles-module--dashedFon--Vvb9y";
export var front = "styles-module--front--3W3HD";
export var phone_block = "styles-module--phone_block--dXkPN";
export var screen_img_wrapper = "styles-module--screen_img_wrapper--HK-B-";
export var topBlockItems = "styles-module--topBlockItems--+Wxqq";
export var topBlock_container = "styles-module--topBlock_container--Mbg5b";
export var topDashedBlock = "styles-module--topDashedBlock--JTXiC";
export var video = "styles-module--video--u9-97";