import React from "react";

const AppleLogo = () => {
    return (
        <svg width="34" height="41" viewBox="0 0 34 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.8511 21.864C27.8511 16.5865 32.1782 14.1363 32.3664 13.9478C29.9206 10.3666 26.1579 9.98965 24.8409 9.80117C21.6426 9.42421 18.6325 11.686 16.9392 11.686C15.246 11.686 12.8003 9.80117 10.1664 9.80117C6.77992 9.80117 3.58162 11.8745 1.70026 14.8902C-1.87431 21.1101 0.759585 30.5342 4.33416 35.6232C6.02738 38.0734 8.09687 40.9007 10.7308 40.9007C13.3647 40.7122 14.3053 39.2043 17.5037 39.2043C20.702 39.2043 21.4545 40.9007 24.2765 40.9007C27.0986 40.9007 28.7918 38.4504 30.485 35.8116C32.5545 32.9844 33.307 30.1572 33.307 29.9687C33.307 30.1572 27.8511 28.0839 27.8511 21.864Z" fill="white"/>
            <path d="M22.583 6.59686C23.8999 4.90052 25.0287 2.45026 24.6525 0C22.583 0 20.1372 1.31937 18.6321 3.20419C17.3152 4.71204 16.1863 7.16231 16.5626 9.61257C18.8202 9.61257 21.266 8.2932 22.583 6.59686Z" fill="white"/>
        </svg>
    )
}
export default AppleLogo