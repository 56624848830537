import React from "react";
import * as styles from "./styles.module.scss";
import * as parentStyles from "../styles.module.scss";
import Footer from "../../components/Footer/Footer";
import Qube from "../../assets/animateImg/Qube/Qube";
import Space from "../../assets/animateImg/Space/Space";
import Phone from "../../assets/animateImg/Phone/Phone";
import TimeHeader from "../../assets/animateImg/TimeHeader/TimeHeader";
import Accordion from "../../components/Accordion/Accordion";
import FadeInSection from "../../components/FadeInSection/FadeInSection";
import FreeModeSwiper from "../../components/FreeModeSwiiper/FreeModeSwiper";
import ScrollTopBtn from "../../components/UI/ScrollTopBtn";
import AppleBtn from "../../components/UI/AppleBtn";


const Explore = () => {
    return (
        <div>
            <ScrollTopBtn />
            <section className={parentStyles.preview}>
                <div className={parentStyles.bigContainer}>
                    <div className={parentStyles.preview__row}>
                        <div className={parentStyles.preview__animations}>
                            <TimeHeader/>
                        </div>
                        <div className={parentStyles.preview__informations}>
                            <FadeInSection>
                                <h2 className={parentStyles.preview__title}>EXPLORE NEW WORLD</h2>
                                <p className={parentStyles.preview__subtitle}>We created the Unisphere metaverse and
                                    made it free from the physical reality limits.</p>
                            </FadeInSection>
                        </div>
                    </div>
                    <div className={parentStyles.preview__scroll}>
                        <FadeInSection>
                            <h5>SCROLL TO EXPLORE</h5>
                            <p className={parentStyles.arrow}></p>
                            <span></span>
                        </FadeInSection>
                    </div>
                </div>
            </section>
            <div className={parentStyles.container}>
                <section className={styles.metaverse}>
                    <div>
                        <div className={styles.metaverse__row}>
                            <h2>
                                <FadeInSection>Welcome to the space of new opportunities for life, communication, travel
                                    and
                                    business.</FadeInSection>
                            </h2>
                            <div className={styles.animation__vase}><FadeInSection>
                                <div className={styles.animation_wrapper}>
                                    <Qube/>
                                </div>
                                <p>Unisphere is the metaverse that augmented the real world. It is based on AR
                                    technology and is accessible via your smartphone.</p></FadeInSection>
                            </div>
                            <div className={styles.animation__space}><FadeInSection>
                                <div className={styles.animation_wrapper}>
                                    <Space/>
                                </div>
                                <p>It’s the first metaverse ecosystem that integrates with different apps. It
                                    contributes to the creation of the AR world with unlimited possibilities.</p>
                            </FadeInSection>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={styles.app}>
                    <div className={styles.app_row}>
                        <div className={styles.app_header}>
                            <span className={styles.bgLine}></span> <FadeInSection>
                            <h2 className={styles.app_title}>Enter your metaverse</h2>
                            <p className={styles.app_subtitle}>Connect to Unisphere with the New Layer app to create
                                your metaverse and interact with other users.</p></FadeInSection>
                        </div>
                        <div className={styles.app_info}><FadeInSection>
                            <div className={styles.app_info_text}>
                                <h2>New Layer app</h2>
                                <p>Unisphere browser. Your ticket to the world of augmented reality. New Layer opens
                                    this
                                    world for everyone.</p>
                                <div className={styles.app_buttons}>
                                    <div className={styles.app_button_wrapper}>
                                        <a href="https://apps.apple.com/us/app/id1559419724"><AppleBtn /></a>
                                    </div>
                                </div>
                            </div>
                        </FadeInSection>
                        </div>
                        <div className={styles.app_phone}>
                            <FadeInSection>
                                <Phone/>
                                <span></span>
                            </FadeInSection>
                        </div>
                    </div>
                </section>
                <section className={styles.explore_slider}>
                    <h3>What you can do in the Unisphere?</h3>
                    <FreeModeSwiper></FreeModeSwiper>
                </section>
                <section className={styles.howUse}>
                    <h2 className={styles.howUse_title}>How to use?</h2>
                    <p className={styles.howUse_subtitle}>There are many abilities of using the Unisphere via New Layer
                        app. Find a guide below. </p>
                    <div className={styles.howUse_accordion_block}>
                        <Accordion/>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    )
}
export default Explore
