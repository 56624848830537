import React from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
import D from '../../images/FreemodeSSwiper/3d.png'
import EX from '../../images/FreemodeSSwiper/Ex.png'
import Target from '../../images/FreemodeSSwiper/target.png'
import Prod from '../../images/FreemodeSSwiper/Prod.png'
import Nft from '../../images/FreemodeSSwiper/Nft.png'
import Game from '../../images/FreemodeSSwiper/Game.png'
import MScreen from '../../images/FreemodeSSwiper/MScreen.png'
import Photo from '../../images/FreemodeSSwiper/Photo.png'
import task from '../../images/FreemodeSSwiper/task.png'
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./styles.scss";

// import required modules
import {FreeMode, Mousewheel, Pagination, Scrollbar} from "swiper";

export default function FreeModeSwiper() {
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                scrollbar={{
                    hide: false,
                }}
                modules={[FreeMode, Pagination, Scrollbar, Mousewheel]}
                mousewheel={true}
                className="free-mode-swiper"
            >
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={D} alt=""/>
                    <p>Fix virtual 3D objects in the real world at specific locations.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={EX} alt=""/>
                    <p>Hold exhibitions in AR and demonstrate art all over the world.</p></SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Target} alt=""/>
                    <p>Place AR navigation in buildings and on streets.</p>
                </SwiperSlide>
                {/*<SwiperSlide className='free-mode-swiper-item'>*/}
                {/*    <img src={AD} alt=""/>*/}
                {/*    <p>Advertise brands in AR space without bureaucracy.</p>*/}
                {/*</SwiperSlide>*/}
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Prod} alt=""/>
                    <p>Demonstrate your products to the customers using AR.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Nft} alt=""/>
                    <p>Buy, sell and demonstrate NFT 2D and 3D art. </p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={task} alt=""/>
                    <p>Use 3D objects and AR technologies for work tasks.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Game} alt=""/>
                    <p>Create and play with friends in AR games and geo quests.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Photo} alt=""/>
                    <p>Make creative photos and videos
                        with 3D objects and spaces.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={MScreen} alt=""/>
                    <p> Place Metascreens with essential information in any place you need.
                    </p>
                </SwiperSlide>
            </Swiper>
            <Swiper

                slidesPerView={1}
                spaceBetween={30}
                freeMode={true}
                scrollbar={{
                    hide: false,
                }}
                modules={[FreeMode, Pagination, Scrollbar, Mousewheel]}
                mousewheel={true}
                className="free-mode-swiper-mobile"
            >
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={D} alt=""/>
                    <p>Fix virtual 3D objects in the real world at specific locations.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={EX} alt=""/>
                    <p>Hold exhibitions in AR and demonstrate art all over the world.</p></SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Target} alt=""/>
                    <p>Place AR navigation in buildings and on streets.</p>
                </SwiperSlide>
                {/*<SwiperSlide className='free-mode-swiper-item'>*/}
                {/*    <img src={AD} alt=""/>*/}
                {/*    <p>Advertise brands in AR space without bureaucracy.</p>*/}
                {/*</SwiperSlide>*/}
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Prod} alt=""/>
                    <p>Demonstrate your products to the customers using AR.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Nft} alt=""/>
                    <p>Buy, sell and demonstrate NFT 2D and 3D art. </p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={task} alt=""/>
                    <p>Use 3D objects and AR technologies for work tasks.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Game} alt=""/>
                    <p>Create and play with friends in AR games and geo quests.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={Photo} alt=""/>
                    <p>Make creative photos and videos
                        with 3D objects and spaces.</p>
                </SwiperSlide>
                <SwiperSlide className='free-mode-swiper-item'>
                    <img src={MScreen} alt=""/>
                    <p> Place Metascreens with essential information in any place you need.
                    </p>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
