import React from 'react';
import * as styles from "./styles.module.scss";
import AppleLogo from "../../assets/appleLogo";

const AppleBtn = () => {
    return <div>
        <a href='https://apps.apple.com/us/app/id1559419724' target='_blank' className={styles.appleBtn}>
            <button>
                <AppleLogo/>
                <div>
                    <p>
                        Download on the
                    </p>
                    <p className={styles.button_place_name}>
                        App Store
                    </p>
                </div>
            </button>
        </a>
    </div>
};

export default AppleBtn;