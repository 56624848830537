import React, {useEffect, useState} from 'react';
import {Collapse} from 'antd';

import "./Accordion.scss"
import axios from "../../api/Api";

const {Panel} = Collapse;

const Accordion = () => {
    const [questions, setQuestions] = useState([])
    useEffect(() => {
        axios.get('/questions/').then(res => setQuestions(res.data))
    }, [])
    console.log(questions)
    return (
        <div className='accordion_container'>
            <Collapse defaultActiveKey={['2']} ghost>
                {questions.map(el => {
                   return  <Panel header={el.question} key={el.question}>
                       <div dangerouslySetInnerHTML={{__html: el.answer}}>

                       </div>
                    </Panel>
                })}
            </Collapse>
        </div>
    )
}
export default Accordion;
