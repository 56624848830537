// extracted by mini-css-extract-plugin
export var animation__space = "styles-module--animation__space--iOM8X";
export var animation__vase = "styles-module--animation__vase--DwkCc";
export var animation_wrapper = "styles-module--animation_wrapper--AiJ8N";
export var app = "styles-module--app--5xGAk";
export var app_button_wrapper = "styles-module--app_button_wrapper--hv4eL";
export var app_buttons = "styles-module--app_buttons--B1SJJ";
export var app_header = "styles-module--app_header--nBKA0";
export var app_info = "styles-module--app_info--emIMk";
export var app_info_text = "styles-module--app_info_text--0IQ1J";
export var app_phone = "styles-module--app_phone--TjzYs";
export var app_row = "styles-module--app_row--6ivMa";
export var app_subtitle = "styles-module--app_subtitle--vv1Zd";
export var app_title = "styles-module--app_title--OuBgt";
export var bgLine = "styles-module--bgLine--d1poe";
export var explore_slider = "styles-module--explore_slider--nWSGB";
export var howUse = "styles-module--howUse--+UVDn";
export var howUse_accordion_block = "styles-module--howUse_accordion_block--jr5Hs";
export var howUse_subtitle = "styles-module--howUse_subtitle--lnj2Z";
export var howUse_title = "styles-module--howUse_title--rBwJN";
export var metaverse = "styles-module--metaverse--2Xjbx";
export var metaverse__row = "styles-module--metaverse__row--+qHU8";