// extracted by mini-css-extract-plugin
export var circle_block = "styles-module--circle_block--SQHx-";
export var circle_container = "styles-module--circle_container--Hc3yk";
export var cometAnimateFigure_container = "styles-module--cometAnimateFigure_container--HMjtb";
export var cometFigure_block = "styles-module--cometFigure_block--c1JWV";
export var comet_back = "styles-module--comet_back--BCU2q";
export var comet_block = "styles-module--comet_block--5b07o";
export var dashed = "styles-module--dashed--Zmzr4";
export var ellipse_bg = "styles-module--ellipse_bg--4smb9";
export var mainFigure_block = "styles-module--mainFigure_block--1BTXE";
export var mainFigure_container = "styles-module--mainFigure_container--0ZaPx";
export var polygon = "styles-module--polygon--3G+Zv";
export var space_container = "styles-module--space_container--hTSxq";
export var star = "styles-module--star--3liMb";